<template>
  <div class="productsHome">
    <Head :type=1></Head>
    <Carousel></Carousel>
    <div class="ph_con">
      <div class="list_container" v-for="(item,index) in productList" :key="index">
        <div style="position: relative;" @mouseenter="enter(index)" @mouseleave="leave">
          <div :class="['list_con',(index+1)%3 == 0 ? 'list_con2' : '']" :style="{backgroundImage: 'url(' + item.pictureAddress + ')'}">
            <p>
                <span :class="[indexShow != index ? '' : 'touming']" >{{item.title}}</span>
            </p>
            <p style="width: 300px;text-align:center">
                <span :class="['p22',indexShow != index ? '' : 'touming']" >{{item.description}}</span>
            </p>
          </div>
          <div class="list_mask" :class="['list_mask',indexShow == index ? 'list_mask1' : '']" >
              <p class="p1">{{item.title}}</p>
              <p class="p2">{{item.description}}</p>
              <div @click="goMore(item)">了解更多→</div>
          </div>
        </div>
      </div>
    </div>
    <RightSide></RightSide>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import Carousel from "@/components/Carousel.vue";
import RightSide from "@/components/RightSide.vue";
import { getProduct } from "@/api/common";
export default {
  name: "productsHome",
  components: {
    Head,
    Footer,
    Carousel,
    RightSide
  },
  data() {
    return {
      indexShow: null,
      productList: [],
    }
  },
  methods: {
    goMore(val) {
      this.$router.push({
        path: '/products',
        query: {
          index: this.indexShow,
          id: val.id
        }
      })
    },
    enter(index) {
        this.indexShow = index
    },
    leave() {
        this.indexShow = null
    },
    // 产品服务列表
    getProduct() {
      let params = {};
      getProduct(params).then((res) => {
        if (res.data.code == 200) {
          this.productList = res.data.data
          console.log(this.productList)
        } else {
        }
      });
    },
  },
  mounted() {
    this.getProduct()
    let wow = new WOW(
      {
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: true,
        callback: function (box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        }
      }
    );
    wow.init();
  },
};
</script>
<style scoped lang="less">
.productsHome {
  overflow: hidden;
  .ph_con {
      width: 1280px;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 180px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .list_container {
        width: 400px;
        height: 400px;
        position: relative;
        padding-right: 40px;
        padding-bottom: 40px;
      }
      .list_container:nth-child(3n+3) {
        padding-right: 0px;
      }
      .list_con {
          width: 400px;
          height: 400px;
          background-size:100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 56px;
          position: relative;
          .p22 {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
          }
      }
      .list_con2 {
          margin-right: 0px;
      }
      .list_mask {
          width: 400px;
          height: 0px;
          background-color: rgba(0,140,212,0.7);
          border-radius: 10px;
          position: absolute;
          top: 0px;
          left: 0px;
          z-index: 10;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
          padding: 0px 33px 0px 42px;
          box-sizing: border-box;
          overflow: hidden;
          transition: height .3s;
          -webkit-transition: height .3s; /* Safari */
          .p1 {
              margin-top: 91px;
              font-weight: 600;
          }
          .p2 {
              margin-top: 50px;
              margin-bottom: 69px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
          }
          div {
              width: 180px;
              height: 48px;
              border: 1px solid #FFFFFF;
              border-radius: 24px;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              text-align: center;
              line-height: 48px;
              margin:0 auto;
              cursor: pointer;
          }
      }
      .list_mask1 {
        height: 400px;
      }
  }
  .touming {
    opacity: 0;
    filter: Alpha(opacity=0);
  }
}
</style>
